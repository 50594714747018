import './GanttCalendar.scss';

import { createPortal } from 'react-dom';
import { DateTime, Interval } from 'luxon';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Button } from 'reactstrap';
import { Popper } from 'react-popper';
import cn from 'classnames';
import { AngleLeft, AngleRight } from 'src/images/icons';
import { getDaysArray, intervalMiddle, intervalsEqual } from 'src/infrastructure/dateUtils';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import {
  IFilterValues,
  IGanttCalendarDayItem,
  IGanttCalendarGroupItem,
  IGanttView,
} from './baseTypes';
import GanttCalendarDay from './subcomponents/GanttCalendarDay';
import GanttSidebar from './subcomponents/GanttSidebar';
import GanttStriper from './subcomponents/GanttStriper';
import DayManager from './managers/dayManager';
import ScrollManager from './managers/scrollManager';
import PlacementManager from './managers/placementManager';
import { Component, createRef } from 'react';
import { FilterList } from 'src/views/components/Page/actions/FilterActionButton';
import { ParsedQuery } from 'query-string';
import { ActionType, FieldDefs } from 'src/views/definitionBuilders/types';
import { CalendarHeader } from './GanttCalendarHeader';

const zoomChange = 2;

interface IGanttCalendarSnapshot {
  middayOffset: number;
}

interface IPopperBoundingElementLike {
  getBoundingClientRect: Element['getBoundingClientRect'];
  clientWidth: Element['clientWidth'];
  clientHeight: Element['clientHeight'];
}

// Based on docs from https://github.com/FezVrasta/react-popper#usage-without-a-reference-htmlelement
class VirtualReference implements IPopperBoundingElementLike {
  private container: Element;
  private sidebar: Element | undefined;

  constructor(container: Element, sidebar: Element | undefined) {
    this.container = container;
    this.sidebar = sidebar;
  }

  readonly getBoundingClientRect = () => {
    var containerBounds = this.container.getBoundingClientRect();
    var sidebarWidth = this.sidebar ? this.sidebar.clientWidth : 0;
    return {
      top: containerBounds.top,
      left: containerBounds.left + sidebarWidth,
      bottom: containerBounds.bottom,
      right: containerBounds.right,
      width: containerBounds.width - sidebarWidth,
      height: containerBounds.height,
      x: containerBounds.x,
      y: containerBounds.y,
      toJSON: containerBounds.toJSON,
    };
  };

  get clientWidth() {
    return this.getBoundingClientRect().width;
  }

  get clientHeight() {
    return this.getBoundingClientRect().height;
  }
}

interface IGanttCalendarProps<T extends IGanttCalendarDayItem> {
  className?: string;
  showInZone: string;
  initialCurrentDay?: DateTime;
  highlightBounds?: Interval;
  getNow?: () => DateTime;
  minRows: number;
  rowHeightPx: number;
  dayWidthPx?: number;
  minZoomFactor: number;
  maxZoomFactor: number;
  items: Array<T>;
  ganttViews?: { [key: string]: IGanttView<T, IGanttCalendarGroupItem> };
  excludeUngroupedView?: boolean;
  filterItemsForUngroupedView?: (item: T, index: number) => boolean;
  storeStateInUrl?: boolean;
  hideDayTitle?: boolean;
  renderItem: (item: T) => React.ReactNode;
  getItemUiKey: (item: T) => string;
  renderItemPopup: (item: T) => React.ReactNode;
  onChangeCurrentDay?: (currentDay: DateTime) => void;
  disableDayControls?: boolean;
  onClosePopup?: () => void;
  filterDefsForSubFilterPills?: FieldDefs[];
  pillContainerId: string;
  viewFilters: { [key: string]: IViewGroupFilterValues };
  handleFilteringChange: (change: Partial<IViewGroupFilterValues>) => void;
}

interface IGanttCalendarState {
  view: string | undefined;
  day: string | undefined;
  dayElementCount: number;
  popupItemId: string | undefined;
  popupTarget: HTMLElement | undefined;
  viewGroupFilterValuesMap: { [key: string]: IViewGroupFilterValues };
  zoomFactor: number;
}

export interface IViewGroupFilterValues {
  search: string;
  filters: IFilterValues;
}

type InternalProps<T extends IGanttCalendarDayItem> = IGanttCalendarProps<T> &
  IQueryParamsProps<{ view: string; day: string }>;

class GanttCalendar<T extends IGanttCalendarDayItem> extends Component<
  InternalProps<T>,
  IGanttCalendarState
> {
  private _unmounted = true;
  private _dayManager = new DayManager(this);
  private _scrollManager = new ScrollManager(this);
  private _placementManager = new PlacementManager(this);
  private _popperRef: HTMLElement | null = null;
  private _zoomChangeSubject = new Subject<'in' | 'out'>();
  private _contentRef = createRef<HTMLDivElement>();
  private _timeIndicatorRef: HTMLElement | undefined;

  private setTimeIndicatorRef = (e: HTMLElement) => {
    this._timeIndicatorRef = e;
    window.requestAnimationFrame(this.redrawTimeIndicator);
  };

  private redrawTimeIndicator = () => {
    if (!this._unmounted) {
      if (this._timeIndicatorRef) {
        const daysToRender = this.getDaysToRender();
        if (!daysToRender.length) {
          return;
        }

        const startOfFirstDay = daysToRender[0];
        const endOfLastDay = daysToRender.slice(-1)[0].endOf('day');
        const currentTime = this._dayManager.now;

        let dayWindowDurationSec = endOfLastDay.diff(startOfFirstDay).as('seconds');

        const hasDstChangeInPeriod = startOfFirstDay.isInDST !== endOfLastDay.isInDST;

        if (hasDstChangeInPeriod) {
          const dstOffsetChange = Math.abs(startOfFirstDay.offset - endOfLastDay.offset) * 60;
          // When DstChanges during the period, the length of the chart will reduce
          if (hasDstChangeInPeriod && startOfFirstDay.isInDST) {
            const sign = currentTime.isInDST ? -1 : 1;
            dayWindowDurationSec += dstOffsetChange * sign;
          } else if (hasDstChangeInPeriod && endOfLastDay.isInDST) {
            const sign = currentTime.isInDST ? -1 : 1;
            dayWindowDurationSec += dstOffsetChange * sign;
          }
        }

        const startOffsetSec = currentTime.diff(startOfFirstDay).as('seconds');

        const position = (startOffsetSec * 100) / dayWindowDurationSec;
        const ref = this._timeIndicatorRef;
        ref.style.left = `${position}%`;
      }
      window.requestAnimationFrame(this.redrawTimeIndicator);
    }
  };

  constructor(props: InternalProps<T>) {
    super(props);

    this.state = {
      view: props.excludeUngroupedView ? Object.keys(props.ganttViews || {})[0] : undefined,
      day: undefined,
      dayElementCount: 0,
      popupItemId: undefined,
      popupTarget: undefined,
      viewGroupFilterValuesMap: {},
      zoomFactor: 1,
    };

    this._dayManager.persistCurrentDay();

    this._scrollManager.updateFromComponent();
    this._scrollManager.currentDayChangeStream.subscribe(dayChange => {
      this._dayManager.setCurrentDay(this._dayManager.currentDay.plus({ days: dayChange }));
    });

    this._zoomChangeSubject
      .scan((zf, dir) => {
        const { minZoomFactor, maxZoomFactor } = this.props;
        const newZf = dir === 'in' ? zf * zoomChange : zf / zoomChange;
        return newZf <= maxZoomFactor && newZf >= minZoomFactor ? newZf : zf;
      }, this.state.zoomFactor)
      .debounceTime(300)
      .subscribe(zf => {
        this.setState({ zoomFactor: zf });
      });
  }

  getSnapshotBeforeUpdate(
    prevProps: Readonly<InternalProps<T>>,
    prevState: Readonly<IGanttCalendarState>
  ) {
    return {
      middayOffset: this._scrollManager.getMiddayOffsetPercentage(),
    };
  }

  componentDidMount() {
    this._unmounted = false;
    const { viewFilters } = this.props;
    this.setState({ viewGroupFilterValuesMap: viewFilters });
  }

  componentDidUpdate(
    prevProps: Readonly<InternalProps<T>>,
    prevState: Readonly<IGanttCalendarState>,
    snapshot: IGanttCalendarSnapshot
  ) {
    this._dayManager.persistCurrentDay();
    this._scrollManager.updateFromComponent();

    if (
      this.state.zoomFactor !== prevState.zoomFactor ||
      this.state.dayElementCount !== prevState.dayElementCount ||
      !intervalsEqual(this.props.highlightBounds, prevProps.highlightBounds)
    ) {
      if (this.props.highlightBounds) {
        const mid = intervalMiddle(this.props.highlightBounds);
        const percentage = mid.diff(mid.startOf('day'), 'minutes').as('minutes') / (24 * 60);
        const offset = percentage - 0.5;
        this._scrollManager.jumpScrollToMiddle(offset);
      } else {
        this._scrollManager.jumpScrollToMiddle(snapshot.middayOffset);
      }
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  private readonly handleZoom = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.altKey) {
      e.preventDefault();
      e.stopPropagation();
      const zoomIn = e.deltaY < 0;
      zoomIn ? this.handleZoomIn() : this.handleZoomOut();
    }
  };

  get dayWidthPx() {
    return (this.props.dayWidthPx || 1500) * this.state.zoomFactor;
  }

  get dayElementCount() {
    return this.state.dayElementCount;
  }

  readonly setDayElementCount = (value: number) => {
    !this._unmounted && this.setState({ dayElementCount: value });
  };

  private readonly getDaysToRender = () => {
    // currentDay is always the centred day in the scroll, so subtract the centred index to get the first day to render
    const firstDay = this._dayManager.currentDay
      .minus({
        days: this._scrollManager.centredDayIdx,
      })
      .startOf('day');
    return getDaysArray(firstDay, this.dayElementCount);
  };

  readonly getRelevantItems = () => {
    const days = this.getDaysToRender();
    if (!days.length) {
      return [];
    }
    const range = Interval.fromDateTimes(days[0], days[days.length - 1]);
    return this.props.items.filter(item => item.bounds.overlaps(range));
  };

  private readonly handleItemClick = (item: T, referenceElement: HTMLElement) => {
    const { popupItemId } = this.state;
    const newPopupItemId = popupItemId === item.id ? undefined : item.id;
    !this._unmounted &&
      this.setState({ popupItemId: newPopupItemId, popupTarget: referenceElement });
    this.manageDocumentListenersForItemPopup(!!newPopupItemId);
  };

  private readonly closeItemPopup = () => {
    !this._unmounted && this.setState({ popupItemId: undefined, popupTarget: undefined });
    this.props.onClosePopup && this.props.onClosePopup();
    this.manageDocumentListenersForItemPopup(false);
  };

  private readonly manageDocumentListenersForItemPopup = (attach: boolean) => {
    const listener = attach ? document.addEventListener : document.removeEventListener;
    listener('mousedown', this.handleDocumentClick);
    listener('keyup', this.handleEscPress);
  };

  private readonly handleDocumentClick = (e: MouseEvent) => {
    if (this._unmounted) {
      return;
    }

    if (
      e.target &&
      !(
        (this.state.popupTarget && this.state.popupTarget.contains(e.target as Node)) ||
        (this._popperRef && this._popperRef.contains(e.target as Node))
      )
    ) {
      // If we see a mouseup in a short enough time, consider this to be a click
      // Otherwise, it's a hold, which is used for click-dragging
      Observable.race(
        Observable.fromEvent(this._contentRef.current!, 'mouseup').mapTo(true),
        Observable.timer(300).mapTo(false)
      )
        .first()
        .subscribe(wasClick => wasClick && this.closeItemPopup());
    }
  };

  private readonly handleEscPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.closeItemPopup();
    }
  };

  readonly getView = () => {
    const { view } = this.props.storeStateInUrl ? this.props.getQueryParams() : this.state;
    return view;
  };

  private readonly handleViewChange = (view: string | undefined) => {
    if (this.getView() !== view) {
      this.closeItemPopup();
      this.scrollContentToTop();
      this.props.storeStateInUrl ? this.props.updateQueryParams({ view }) : this.setState({ view });
    }
  };

  readonly getDay = () => {
    const { day } = this.props.storeStateInUrl ? this.props.getQueryParams() : this.state;
    return (
      day ||
      (this.props.highlightBounds && intervalMiddle(this.props.highlightBounds).toISODate()) ||
      (this.props.initialCurrentDay && this.props.initialCurrentDay.toISODate())
    );
  };

  readonly handleDayChange = (value: DateTime) => {
    const day = value.toISODate();
    if (this.getDay() !== day) {
      this.closeItemPopup();
      this.props.storeStateInUrl ? this.props.updateQueryParams({ day }) : this.setState({ day });
    }
  };

  private readonly getGroupFilterValues = () => {
    const viewName = this.getView();
    if (this.props.viewFilters) {
      return {
        search: this.props.viewFilters[viewName || '']?.search ?? '',
        filters: this.props.viewFilters[viewName || '']?.filters ?? {},
      };
    }

    return {
      search: '',
      filters: {},
    };
  };

  private readonly handleViewSearchChange = (searchValue: string) => {
    this.props.handleFilteringChange({ search: searchValue });
  };

  private readonly handleFilter = (values: IFilterValues) => {
    this.props.handleFilteringChange({ filters: values });
  };

  private readonly handleFilterReset = () => {
    const ganttView = this._placementManager.getGanttView();
    return (ganttView && ganttView.defaultFilters) || {};
  };

  private readonly canZoomIn = () => {
    return this.state.zoomFactor * zoomChange <= this.props.maxZoomFactor;
  };

  private readonly canZoomOut = () => {
    return this.state.zoomFactor / zoomChange >= this.props.minZoomFactor;
  };

  private readonly handleZoomIn = () => {
    this.canZoomIn() && this._zoomChangeSubject.next('in');
  };

  private readonly handleZoomOut = () => {
    this.canZoomOut() && this._zoomChangeSubject.next('out');
  };

  private readonly getPopupBoundaries = (): Element | undefined => {
    function getFirstByClass(className: string): Element | undefined {
      return document.getElementsByClassName(className)[0];
    }

    const containerElement = getFirstByClass('area-content');
    if (!containerElement) {
      return undefined;
    }

    // Popup boundaries typed as Element, even though that's much more than they need, so force a cast
    return (new VirtualReference(
      containerElement,
      getFirstByClass('gantt-sidebar')
    ) as unknown) as Element;
  };

  private readonly handleContentScroll = () => {
    if (this._contentRef.current) {
      this._contentRef.current.setAttribute(
        'style',
        '--time-rail-offset:' + this._contentRef.current.scrollTop + 'px'
      );
    }
  };

  private readonly scrollContentToTop = () => {
    if (this._contentRef.current) {
      this._contentRef.current.scrollTop = 0;
    }
  };

  private readonly setCurrentDay = (value: DateTime) => {
    this._dayManager.setCurrentDay(value);
    if (this.getView() === undefined) {
      this.scrollContentToTop();
    }
  };

  private readonly setCurrentDayToNextDay = () => {
    this._scrollManager.scrollForward24hours();
    if (this.getView() === undefined) {
      this.scrollContentToTop();
    }
  };

  private readonly setCurrentDayToPrevDay = () => {
    this._scrollManager.scrollBackward24hours();
    if (this.getView() === undefined) {
      this.scrollContentToTop();
    }
  };

  render() {
    const {
      className,
      showInZone,
      minRows,
      rowHeightPx,
      hideDayTitle,
      highlightBounds,
      ganttViews,
      excludeUngroupedView,
      renderItem,
      renderItemPopup,
      items,
      disableDayControls,
      filterDefsForSubFilterPills,
      pillContainerId,
    } = this.props;
    const { popupItemId, popupTarget } = this.state;
    const popupItem = popupItemId ? items.find(i => i.id === popupItemId) : undefined;
    const viewName = this.getView();
    const ganttView = this._placementManager.getGanttView();
    const daysToRender = this.getDaysToRender();
    const groupFilterValues = this.getGroupFilterValues();

    const sizedGroups = this._placementManager.getSizedGroups(groupFilterValues);
    const [lastGroup] = sizedGroups.slice(-1);
    const rowCount = Math.max(minRows, lastGroup ? lastGroup.rowIndex + lastGroup.rowsUsed : 0);
    const popupBoundaries = this.getPopupBoundaries();
    return (
      <div className={cn('gantt-calendar-component', className)} onWheel={this.handleZoom}>
        <CalendarHeader<T>
          today={this._dayManager.today}
          day={this._dayManager.currentDay}
          hideDayTitle={!!hideDayTitle}
          onDayChange={this.setCurrentDay}
          ganttViews={ganttViews}
          excludeUngroupedView={!!excludeUngroupedView}
          currentView={viewName}
          onViewChange={this.handleViewChange}
          canZoomIn={this.canZoomIn() && !disableDayControls}
          canZoomOut={this.canZoomOut() && !disableDayControls}
          onZoomIn={this.handleZoomIn}
          onZoomOut={this.handleZoomOut}
          pillContainerId={pillContainerId}
        />
        <div
          className="content"
          ref={this._contentRef}
          onScroll={this.handleContentScroll}
          style={{ '--time-rail-offset': `${this._contentRef.current?.scrollTop ?? 0}px` } as any}>
          {ganttView && viewName && (
            <GanttSidebar
              key={viewName}
              className="gantt-sidebar"
              rowHeightPx={rowHeightPx}
              renderGroup={ganttView.renderGroup}
              sizedGroups={sizedGroups}
              groupFilterValues={groupFilterValues}
              onSearchChange={this.handleViewSearchChange}
              filterTitle={`Filter ${ganttView.label}`}
              filterFields={ganttView.filterFields}
              filterDef={ganttView.filterDef}
              onFilter={this.handleFilter}
              onFilterReset={this.handleFilterReset}
            />
          )}
          <GanttStriper
            className="gantt-striper"
            rowHeightPx={rowHeightPx}
            sizedGroups={sizedGroups}
          />
          <div className="day-viewport-clipper" ref={this._scrollManager.setViewportClipperRef}>
            <div
              className="day-viewport"
              onScroll={this._scrollManager.scrollOccurred}
              ref={this._scrollManager.setViewportRef}>
              <div className="day-container" ref={this._scrollManager.setDayContainerRef}>
                <time className="current-time-indicator" ref={this.setTimeIndicatorRef} />
                {daysToRender.map((d, i) => (
                  <GanttCalendarDay
                    key={i}
                    showInZone={showInZone}
                    dayWidthPx={this.dayWidthPx}
                    totalRows={rowCount}
                    rowHeightPx={rowHeightPx}
                    day={d}
                    highlightBounds={highlightBounds}
                    placedItems={this._placementManager.getPlacedItemsByDay(
                      d,
                      i === 0,
                      groupFilterValues
                    )}
                    renderItem={renderItem}
                    onItemClick={this.handleItemClick}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="day-navigation">
            <Button
              className="backward-24h"
              title="Backward 24 Hours"
              outline
              disabled={disableDayControls === true}
              onClick={this.setCurrentDayToPrevDay}>
              <AngleLeft size="2x" />
            </Button>
            <Button
              className="forward-24h"
              title="Forward 24 Hours"
              outline
              disabled={disableDayControls === true}
              onClick={this.setCurrentDayToNextDay}>
              <AngleRight size="2x" />
            </Button>
          </div>
          <div>
            {popupItem &&
              createPortal(
                <div className="gantt-calendar-popup-container">
                  <Popper
                    innerRef={e => (this._popperRef = e)}
                    target={popupTarget}
                    modifiers={{
                      offset: { offset: '0px, 5px' },
                      preventOverflow: {
                        boundariesElement: popupBoundaries,
                        escapeWithReference: false,
                      },
                      flip: {
                        boundariesElement: popupBoundaries,
                      },
                    }}>
                    <div>{renderItemPopup(popupItem)}</div>
                  </Popper>
                </div>,
                document.getElementById('shell-popup-location') as HTMLElement
              )}

            {document.getElementById(pillContainerId) &&
              createPortal(
                <FilterList
                  actionDef={{
                    actionType: ActionType.filterActionButton,
                    filterFields: filterDefsForSubFilterPills || [],
                  }}
                  actionData={{
                    actionValue: (groupFilterValues && groupFilterValues.filters) || {},
                    paneValue: '',
                    panelValue: '',
                    parentValue: '',
                    sectionValue: '',
                  }}
                  queryParams={groupFilterValues.filters as ParsedQuery}
                  onSetQueryParams={(filters: {
                    [key: string]: string | Array<string> | Array<number> | undefined;
                  }) => {
                    // licence class is only filtered if licenceType is. Licence class isn't cleared
                    // here when licenceType is, so manually correct it so pills don't render
                    const licenceClassIds =
                      filters.licenceTypeId === undefined ? undefined : filters.licenceClassIds;
                    this.props.handleFilteringChange({
                      filters: { ...filters, licenceClassIds: licenceClassIds },
                    });
                  }}
                />,
                document.getElementById(pillContainerId) as HTMLElement
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default withQueryParams(GanttCalendar);
